import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button, Card, Image, QRCode, QRPropsCanvas, QRCodeProps, Spin, Flex, Progress } from "antd";
import { RightOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import "./App.css";
import logo_black from "./assets/logo.png";
import logotrans from "./assets/logo-white.png";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import html2canvas from "html2canvas";
// @ts-ignore
import { useScreenshot, createFileName } from 'use-react-screenshot'


const dfmsg ="Please enter your email address and redemption code to redeem youreSIM. You will receive an email with instructions on how to download  your eSIM profile. "
const redesim = "ESIM sucesfully redeemed. Please scan the QR code on your phone. An email is also sent with redemption instructions."

function App() {

  let objectid_store = ""
  let uploadurl_link = ""
  let dataurlobject = ""
  let qrvalue = "https://ant.design/"
  let serlno = ""

  const [ses, setSes] = useState("");
  const [dispProgress, setDispProgress] = useState("");
  const [pgStatus, setPgStatus] = useState("none");
  const [message, setMessage] = useState(dfmsg);
  const [redeem, setRedeem] = useState(null);
  const [size, setSize] = useState<number>(250);
  const [err, setErr] = useState('');
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [qrvalued, setQrvalued] = useState<string>("https://ant.design/");
  const [qrImage, setQrImage] = useState<string>("")
  const [planDays, setPlanDays] = useState<string>("15");
  const [serialNo, setSerialNo] = useState<string>("89862100094500144988");
  const [planName, setPlanName] = useState<string>("");
  const [percent, setPercent] = useState<number>(20);
  const [timer, setTimer] = React.useState(0);
  const [timerDuration, setTimerDuration] = React.useState(0);
  const [showCheckcoupon, setShowCheckcoupon] = useState('none');
  const [showvalidCoupon, setShowValidCoupon] = useState('none');
  const [showinvalidCoupon, setShowInvalidCoupon] = useState('none');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  //Screenshot components 
  const ref = useRef(null)
  const [image, takeScreenshot] = useScreenshot()
  const getImage = () => takeScreenshot(ref.current).then(download)

  // Define styles for the container and text elements
  const containerStyle = {
    display: 'flex',
    alignItems: 'center', // This ensures vertical alignment is centered
    justifyContent: 'left', // Adjusts items to be more side by side
    padding: '10px 0', // Reduced padding, adjust as needed
  };

  const titleStyle = {
    margin: '0 10px 0 0', // Adds margin to right of title for spacing, adjust as needed
    fontSize: '17px',
    fontWeight: 'bold',
  };

  const detailStyle = {
    margin: 0, // Removes default paragraph margin
    fontSize: '17px',
  };

  useEffect(() => {
    // Update the document title using the browser API
  }, [qrvalued, planDays, planName, serialNo, qrImage]);

  //API call header params
  // Get the AppId and AppSecret from JoyTel.
  const AppId = "JnmQXCOhqjcc";
  const AppSecret = "ACE010516F7B49449E49F377663F3E2A";
  // Millisecond timestamp here
  const timestamp = new Date().getTime();
  // TransId, generate your own Transaction Id
  const TransId = uuidv4();
  // const TransId = "eae3e0b1-bcef-4fae-86cd-b7930f7da954"
//   const TransId = "c0e6ea8f-a5b2-4d64-a89c-5f40d90df4e2"
  // Create Plain Text for MD5
  const plainText = AppId + TransId + timestamp + AppSecret;
  // Generate MD5 Hash (Cipher Text)
  const cipherText = CryptoJS.MD5(plainText).toString();
  // Use cipherText as needed, for example, send it in an API request.
  // console.log('MD5 Hash:', cipherText);

  const download = (image:any, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const submitData = async (couponcode:any) => {  
    try {
      const input = {
        "coupon" : couponcode,
        "qrcodeType": 1
      };
      const response = await fetch("https://acrp.shukran.tech/coupon/redeem", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "AppId": AppId,
          "TransId": TransId,
          "Timestamp": timestamp.toString(),
          "Ciphertext": cipherText,
        },
        body: JSON.stringify(input),
      });
      if (!response.ok) {
        console.error(`HTTP status ${response.status}`);
        return false;  // Indicate failure
      }
      const data = await response.json();
      const errorCodes = ["502", "606", "401", "403", "406", "407", "500", "501", "600", "601", "602", "999"];

      if (errorCodes.includes(data.code)) {
        alert(data.mesg);
        return false;  // Indicate specific application error
      }
      // if (data.code === "502" || data.code === "606") {
      //   alert(data.mesg);
      //   return false;  // Indicate specific application error
      // }
      console.log("submitData success:", JSON.stringify(data));
      return true;  // Indicate success
     } catch (error) {
        console.error("Error:", error);
  }
}


const renderTime = ({ remainingTime }: any) => {
    if (remainingTime === 0) {
      return <div className="timer">Please try again...</div>;
    }
  
    return (
      <div className="timer">
        <div className="text">Redeeming...</div>
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };


const sendtoDBTest = async (couponcode:any, custemail:any) => {

  try {
      const response = await fetch('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-redemption', {
        method: 'POST',
        body: JSON.stringify({
          couponCode: couponcode,
          custEmail: custemail,
          transId : TransId
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log('result is: ', JSON.stringify(result, null, 4));
      console.log("Dataset inserted dyanmodb")
      //alert("Dataset succesfully inserted!");
    } catch (err) {
      setErr((err as Error).message);
    } finally {
      //window.location.href = "/tenants"
    }
}

const checkcouponValue = async (couponcode:any) => {

    try {
        const response = await fetch('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/check/coupon', {
          method: 'POST',
          body: JSON.stringify({
            couponcode: couponcode
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        // if (!response.ok) {
        //   throw new Error(`Error! status: ${response.status}`);
        // }
        const result = await response.text();
        console.log(result)
        if (result === "true") {
          setShowCheckcoupon('none')
          setShowValidCoupon('flex')
          setShowInvalidCoupon('none')
          setButtonDisabled(false)
        } else {
          setShowCheckcoupon("none");
          setShowInvalidCoupon('flex')
          setShowValidCoupon('none')
          setButtonDisabled(true)
        }
        // console.log('result is: ', JSON.stringify(result, null, 4));
        // console.log("Dataset inserted dyanmodb")
        //alert("Dataset succesfully inserted!");
      } catch (err) {
        setErr((err as Error).message);
      } finally {
        //window.location.href = "/tenants"
      }
  }

const isApiResponseEmpty = (response: any) => {
    return response.trim() === "\"none\"";
  };

  let attempts = 0; // Define attempts outside the function
  const maxAttempts = 10;
  
 async function getCallbackData(couponCode:any) {
      let apiTimeout = setTimeout(getCallbackData, 10000);
      clearTimeout(apiTimeout);
      fetch('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-redemption/poll?transId=' + TransId + "&couponcode=" + couponCode, {
          method: 'GET',
      })
      .then(response => response.text())
      .then(data => {
          console.log(data);
          const checkjson = isApiResponseEmpty(data);
          console.log(checkjson)
          if (!checkjson) {
            // Process the response and update the view.
            // Reset attempts if data is received.
            clearTimeout(apiTimeout);
            console.log('Data received:', JSON.parse(data));
            const rData = JSON.parse(data)
            attempts = 0;
            setSes("Redeemed");
            setQrImage(rData['qrImage'])
            setPlanName(rData['salePlanName'])
            return data;
        } else {
            attempts++;
            console.log(attempts);
        }
        if (attempts < maxAttempts) {
            // Recreate a setTimeout API call which will be fired after 1 second.
            apiTimeout = setTimeout(() => getCallbackData(couponCode), 10000);
        } else {
            if (checkjson) {    
                clearTimeout(apiTimeout);
                // Failure case. If required, alert the user.
                alert("Failed to fetch data after 10 attempts.");
            } else {
                // Process the response and update the view.
                clearTimeout(apiTimeout);
                console.log('Data received:', data);
            }
        }
          console.log('Data received:', data);
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  }

const onFinish = async (values: any) => { 
  setLoadingForm(true);
  setPgStatus("flex");
  setTimerDuration(60);
  console.log('Success:', values);
  await sendtoDBTest(values.rcode, values.email)
  const submitSuccess = await submitData(values.rcode);
  if (!submitSuccess) {
    console.error('submitData failed');
    return cleanUp("Submission failed. Please try again or contact our support team.");
  }  // If this fails, the following lines will not execute
  console.log('submitData completed');  // Check if this logs when there's an error
  setTimeout(() => {
    getCallbackData(values.rcode)
  }, 5000);

}

function cleanUp(errorMessage:any) {
    console.error(errorMessage);
    setLoadingForm(false);
    setPgStatus("none");
    alert(errorMessage);
    window.location.reload();
  }

return (
    <div
      ref={ref}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Card type="inner" style={{ width: 550, border: 'none' }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image preview={false} width={200} src={logotrans}></Image>
        </div>
        {!ses && (
          <>
        <Card style={{border: 'none'}}>
          <h1 style={{ textAlign: "center" }}>eSIM Redemption</h1>
          <p> Please enter your email address and redemption code to redeem your eSIM. 
          You will receive an email with instructions on how to install your eSIM profile </p>
          <h2 style={{ textAlign: "center" }}>eSIM QR Redemption Process</h2>
          <p> <b>1.</b> Input your email address 
            <br />
            [Gmail, Yahoo, Outlook, and Hotmail preferred]
            <br />
            <b>2.</b> Confirm your email address 
            <br /> 
            <b>3.</b> Copy and paste the redemption code directly found in the email received
            <br />
            [Do not manually type in the redemption code]
            <br />
            <b>4.</b> Download the eSIM QR code when it appears on the screen. 
            A confirmation email with the QR Code will be sent.
          </p>
        </Card>
        <h3 style={{color:'red', textAlign:'center'}}>
          <b>Important: QR Code is only valid 30 Days from the date of Redemption</b>
        </h3>
        <p>If your travel date is more than 30 days from today, kindly redeem your eSIM closer to your travel date. After 30 days, the QR Code will no longer be usable.</p>
        <b>No refund requests will be entertained if redemption is done too early.</b>

        <div style={{
          display: pgStatus,
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2vh",
          
        }}>
          <CountdownCircleTimer
            key={timer}
            isPlaying
            duration={timerDuration}
            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[7, 5, 2, 0]}
            size={150}
            strokeWidth={6}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
        <Spin spinning={loadingForm}>
        <Form
          name="redemption"
          labelCol={{ span: 8 }}
          wrapperCol={{ flex: 1 }}
          labelAlign="left"
          labelWrap
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            style={{paddingTop: "30px"}}
            rules={[{ type: 'email',  required: true, message: "Please input your email!" }]}
          >
          <Input placeholder='Input your email here' style={{height:"40px"}} />
          </Form.Item>
          <Form.Item
            label="Confirm Email"
            name="cemail"
            rules={
              [
                { type: 'email', required: true, message: "Please confirm your email!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two emails that you entered do not match!'));
                  },
                }),
              ]}
          >
            <Input placeholder='Confirm your email is correct' style={{height:"40px"}} />
          </Form.Item>
          <Form.Item
            label="Redemption Code"
            name="rcode"
            rules={[
              { required: true, message: "Please input your redemption code!" },
              {
                pattern: /^[a-zA-Z0-9]{10}$/,
                message: "Redemption code must be exactly 10 characters!"
              }
            ]}
          >
            <Input 
                placeholder='Copy and Paste the Redemption Code' 
                style={{height:"40px"}} 
                onChange={(e) => {
                    const value = e.target.value;
                    if (value.length >= 10) {
                        setShowCheckcoupon("flex");
                        checkcouponValue(value);
                    }
                    else {
                        setShowInvalidCoupon('none')
                        setShowValidCoupon('none')
                        setShowCheckcoupon("none")
                        setButtonDisabled(true)
                    }
                }}
            />
            
          </Form.Item>
          <div style={{display:showCheckcoupon}}><Spin /> Checking coupon... </div>
          <div style={{display:showvalidCoupon}}><CheckCircleTwoTone twoToneColor="#52c41a" /> Valid Coupon </div>
          <div style={{display:showinvalidCoupon}}><CloseCircleTwoTone twoToneColor="#eb2f96" /> Invalid Coupon. Please paste correct coupon from email or coupon has already been used </div>
          
          <br />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", height: "40px" }}
              // onClick={redeemqr}
              disabled={buttonDisabled}
            >
              Submit
              <RightOutlined />
            </Button>
          </Form.Item>
        </Form>
        </Spin>

          <div style={{ textAlign: "right", paddingBottom: 100 }} > 
            {/* <Button type="link">Resend Email</Button> */}
            <Button type="link" href="https://wa.me/+6591410101">Need Help?</Button>
          </div>
        </>
        )}
        {ses && (
          <>

          <Card style={{border: 'none', padding:0}}>
          <h1 style={{ textAlign: "center", margin:0}}>eSIM Redemption</h1>
          <h2 style={{ textAlign: "center", margin:0 }}>Redemption Successful</h2>
          </Card>
          <h3 style={{textAlign: 'left', margin:0}}>
          Apple Devices:</h3> <p>Take a screenshot to save QR in album.</p>
          <br />
          <h3 style={{textAlign: 'left', margin:0}}>
          Android Devices:</h3> <p>Press the Download QR button to save QR in album.</p> 

          <p><b>A copy of the QR code will be sent to the email address provided.</b></p>
          


          <div 

            id="myqrcode"
            style={{alignContent: "center", justifyContent: "center", display: "grid"}}
          >

            <div><img src={qrImage} style={{width: 350, height: 350}} alt="qrcode"></img></div>

          </div>

          <div
            style={{alignContent: "center", justifyContent: "center", display: "flex", paddingTop: 20, paddingBottom: 20}}
          >

          <Button type="primary" style={{width:200}} href={qrImage}  ghost>
            Download QR Image
          </Button>
          </div>
          <div
            style={{alignContent: "center", justifyContent: "center", display: "flex", paddingBottom: 20}}
          >
          {/* <Button type="primary" style={{width:200}} onClick={getImage} ghost>
            Take Screenshot
          </Button> */}
          </div>
          {/* <div style={containerStyle}>
            <h3 style={titleStyle}>Plan Days:</h3>
            <p style={detailStyle}>{planDays}</p>
          </div> */}
          <div style={containerStyle}>
            <h3 style={titleStyle}>Plan Details:</h3>
            <p style={detailStyle}>{planName}</p>
          </div>
          </>
        )}
      </Card>
    </div>
  );
}

export default App;
