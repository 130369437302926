import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from './App';
import Support from './Support';
import Test from './Test';
import TestPolling from './Test_polling';
import Mantainence from './mantainence';

const Main = () => {
  return (
    <Router> {/* The Switch decides which component to show based on the current URL.*/}
        <Routes>
            <Route path='/' element={<App />}></Route>
            <Route path='/test' element={<TestPolling />}></Route>
            <Route path='/support' element={<Support />}></Route>
            
        </Routes>
    </Router>
    // <Mantainence />
  );
}

export default Main;