import { Card, Image } from 'antd';
import logotrans from "./assets/logo-white.png";

function Support() {
    return (
        <>
        <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Card type="inner" style={{ width: 550, border: 'none' }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image preview={false} width={200} src={logotrans}></Image>
        </div>
        <h2 style={{textAlign:"center"}}>Please Click on the widget below for support</h2>
        </Card>
        <div style={{ textAlign: 'center', position: 'relative', height: '500px' }}>
           
            {/* Adjust the top and right properties to position the arrow as needed */}
            <div style={{ 
                position: 'absolute',
                top: '110%', // Adjust this value to move the arrow up or down
                right: '40px', // Adjust this value to align with your chat prompt
                fontSize: '40px',
                transform: 'translateY(-50%)'
            }}>
                ↓
                ↓
                ↓
                ↓
                ↓
                ↓
                ↓
                ↓
                ↓
                
                
                
            </div>
        </div>
        </div>
        </>
    );
}
export default Support;



